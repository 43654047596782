import axios from '@/plugins/axios'

const state = {
    usertoken: localStorage.getItem('token') || '',
}

const getters = {
    usertoken(state) {
        return state.usertoken
    },
    userinfo(state) {
        let b64DecodeUnicode = str =>
          decodeURIComponent(
            Array.prototype.map.call(atob(str), c =>
              '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            ).join(''))

        let parseJwt = token =>
          JSON.parse(
            b64DecodeUnicode(
              token.split('.')[1].replace('-', '+').replace('_', '/')
            )
          )
        if (!state.usertoken) {
            return {}
        }
        
        console.log ( parseJwt ( state.usertoken ) )
        
        return parseJwt ( state.usertoken )
    },
    isLoggedIn(state) {
        return state.usertoken !== ''
    }
}

const actions = {
    async login({
        commit
    }, event) {
        let loginResponse = await axios({
            url:'/auth/login',
            method: 'POST',
            data: {
                email: event.target[0].value,
                password: event.target[1].value
            }
        })
        
        console.log ( loginResponse )
        
        if (!loginResponse && !loginResponse.data.access_token) {
            return false
        }
        localStorage.setItem('token', loginResponse.data.access_token)
        axios.defaults.headers.Authorization = `JWT ${loginResponse.data.access_token}`
        commit('LOGIN', {
            token: loginResponse.data.access_token
        })
        return true
    },
    logout({commit}) {
        localStorage.removeItem('token')
        commit('LOGOUT')
    },
    resetPassword({commit}, resetPassword) {
        commit('RESET_PASSWORD', resetPassword)
    },
    forgotPassword({commit}, forgotPassword) {
        commit('FORGOT_PASSWORD', forgotPassword)
    },
}

// mutations
const mutations = {
    LOGIN(state, payload) {
        state.usertoken = payload.token
    },
    LOGOUT(state) {
        state.usertoken = ''
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}