import axios from 'axios'
import Vue from 'vue'

let schema = process.env.VUE_APP_API_SCHEMA || 'https://';
let host = process.env.VUE_APP_API_HOST || 'data.mars.inoe.ro/api/v1.0';

const axiosInstance = axios.create({

    baseURL: `${schema}${host}/`,
    headers: {
        'Authorization': 'JWT ' + localStorage.getItem('token')
    },
    // withCredentials: true
})

function plugin(app, axios) {
    if (plugin.installed) {
        return;
    }
    plugin.installed = true;

    Object.defineProperties(app.prototype, {
        axios: {
            get: function get() {
                return axios;
            }
        },
        $http: {
            get: function get() {
                return axios;
            }
        }
    });

    app.axios = axios;
    app.$http = axios;
}

Vue.use(plugin, axiosInstance);

export default axiosInstance
