<template>
  <v-app>
    <v-app-bar
      app
      color="dark-grey"
      dark
      v-if="false"
    >
      
      <v-app-bar-nav-icon @click="drawer = !drawer" v-if="$vuetify.breakpoint.smAndDown"></v-app-bar-nav-icon>

      <template v-if="!$vuetify.breakpoint.smAndDown">
        <v-spacer></v-spacer>
        <v-btn to="/current-series" text>
          <span>Instrumente</span>
        </v-btn>
        <v-btn to="/teams" v-if="isLoggedIn" text>
          <span>Cluburi</span>
        </v-btn>
        <v-btn v-if="isLoggedIn" to="/messages" text>
          <v-badge
            :color="newMessages ? 'pink' : null"
            dot
          >
            <span>Mesaje</span>
          </v-badge>
        </v-btn>
        <v-btn v-if="isLoggedIn" to="/profile" text>
          <span>Contul meu</span>
        </v-btn>
        <v-btn v-if="isLoggedIn" to="/logout" text>
          <span>Deconectare</span>
        </v-btn>
        <v-btn v-if="!isLoggedIn" to="/login" text>
          <span>Conectare</span>
        </v-btn>
        <v-spacer></v-spacer>
      </template>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
          <router-link to="/current-series" text :class="['text-decoration-none']">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-medal</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Instrumente
              </v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link v-if="isLoggedIn" to="/teams" text :class="['text-decoration-none']">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Cluburi
              </v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link v-if="isLoggedIn" to="/messages" text :class="['text-decoration-none']">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Mesaje
              </v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link v-if="isLoggedIn" to="/profile" text :class="['text-decoration-none']">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Contul meu
              </v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link v-if="!isLoggedIn" to="/login" text :class="['text-decoration-none']">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-login</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Conectare
              </v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link v-if="isLoggedIn" to="/logout" text :class="['text-decoration-none']">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                Deconectare
              </v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
    export default {
        name: 'App',
        data: () => ({
            isLoggedIn: false,
              group: "actions",
              drawer: false,
              messageGetterInterval: null,
              newMessages: 0,
              lastMessagesChecked: 0
        })
    }
</script>