<template>
  <div class="home">
    <v-layout row wrap class="my-5" align-center>
      <v-flex xs12 offset-xs0 sm10 offset-sm1 md8 offset-md2 lg6 offset-lg3>
        <template>
            <v-row class="mb-4">
                <v-col cols="12" justify="center" align="center">
                    <div class="text-center text-h3 font-weight-black black--text transition-swing my-8">
                        <template>
                            RADO-Bucharest: Measurement Database
                        </template>
                    </div>
                </v-col>
            </v-row>
            <v-row class="mb-8">
                <v-col cols="8">
                    <p class="text-body-2">
                    Access to the RADO-Bucharest data products is non-competitive and free of charge, subject to its availability.
                    </p>
                    <v-tabs fixed-tabs background-color="indigo" dark v-model="introTab">
                        <v-tab id="data-availability">Data Availability</v-tab>
                        <v-tab id="data-access">Data Access</v-tab>
                        <v-tab id="data-policy">Data Policy</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="introTab" class="mt-2">
                        <v-tab-item href="#data-availability">
                            <p class="text-body-2">
                                Data availability can be viewed in this portal.
                            </p>
                            <p class="text-body-2">
                                The table below provides a quick overview of available data at our sites for each month. You can select the desired month using the calendar dropdown.
                            </p>
                            <p class="text-body-2">
                                To check data available from each instrument you can expand the laboratory line in the table below by clicking on the chevron.
                            </p>
                            <p class="text-body-2">
                                Information regarding equipment can be consulted by clicking the information icon, whilst information about available data (products, files, measurement dates) can be obtained by clicking the corresponding green cell in the table.
                            </p>
                        </v-tab-item>
                        <v-tab-item href="#data-access">
                            <p class="text-body-2">
                                Access to data is granted based on a request submitted in the <strong><a href="http://carport.inoe.ro/forms/index.php/735818?lang=en">"RADO-Bucharest: request for accessing the infrastructure"</a></strong> portal.
                            </p>
                            <p class="text-body-2">
                                The user should briefly describe the purpose of collecing the data and should list the datasets concerned. After verifying the request (availability of data, mode of use, confirmation of compliance with the access policy), RADO-Bucharest will make available to the applicant (via email, ftp or directly from ACTRIS portal) the concerned data products.
                            </p>
                            <p class="text-body-2">
                                To avoid misuse of specialized data, access to pre-processed and raw data will be granted strictly to experienced users.
                            </p>
                        </v-tab-item>
                        <v-tab-item href="#data-policy">
                            <p class="text-body-2">
                                RADO-Bucharest data and digital tools are made available under the terms of the <strong>Creative Commons CC BY 4.0 SA</strong> license for data and metadata, and respectively open source licenses for software. The user can freely access and use RADO-Bucharest data, with the simultaneous observance of two conditions:
                            </p>
                            <p class="text-body-2">
                                <ol type="1">
                                    <li>
                                        <p>Data may be copied, distributed, transformed and combined if credit is given as specified below.</p>
                                        <ol type="a">
                                            <li>Users of the data and digital tools developed by RADO-Bucharest must include in the derived works the following paragraph as a form of credit recognition: "The authors would like to acknowledge RADO-Bucharest (INOE) for providing the data used in this study".</li>
                                            <li>Users must cite a reference <a href="http://environment.inoe.ro/category/46/publications">article published by RADO-Bucharest</a> when using the data in publications. In case of significant use of the data in publications, the user must offer the possibility of co-authorship.</li>
                                        </ol>
                                    </li>
                                </ol>
                            </p>
                            <p class="text-body-2">
                                The right to change does not include the right to falsify data, scientific integrity must always be pursued and respected, and use must be in accordance with ethical guidelines.
                            </p>
                            <p class="text-body-2">
                                <ol type="1" start="2">
                                    <li>
                                        <p>Derived data cannot be subject to conditions more restrictive than those of the license applied to the original data (CC BY 4.0 SA). In exceptional and well-justified cases the user may request the removal of this condition.</p>
                                    </li>
                                </ol>
                            </p>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="4">
                    <div class="pb-2">
                        <div class="status data-purple-1 header" style="display: inline-block !important; width: 32px;"></div>
                        <div class="status data-purple-2 header" style="display: inline-block !important; width: 32px;"></div>
                        <div class="status data-purple-3 header" style="display: inline-block !important; width: 32px;"></div>
                        <div class="status data-purple-4 header" style="display: inline-block !important; width: 32px;"></div>
                        <div class="status data-purple-5 header mr-4" style="display: inline-block !important; width: 32px;"></div>
                        <span class="text-body-2 black--text d-block">Number of instruments providing data</span>
                    </div>
                    <v-divider></v-divider>
                    <div class="py-2">
                        <div class="status data-instrument-1 header mr-4 d-block" style="width: 32px;"></div>
                        <span class="text-body-2 black--text">Raw data available</span>
                    </div>
                    <v-divider></v-divider>
                    <div class="pt-2">
                        <div class="status data-instrument-2 header mr-4 d-block" style="width: 32px;"></div>
                        <span class="text-body-2 black--text">Processed data available</span>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-data-table
                    dense
                    :headers="dataTableHeaders"
                    :items="monthData.data"
                    class="dataByDayTable"
                    show-expand
                    item-key="name"
                    hide-default-footer
                    hide-default-header
                    :loading="dataLoading"
                    loading-text="Loading... Please wait"
                >
                    <template v-slot:body.prepend>
                        <tr>
                            <td style="width: 5%"></td>
                            <td>
                                <v-menu
                                    ref="dateMenu"
                                    v-model="dateMenu"
                                    :close-on-content-click="false"
                                    transition="slide-y-transition"
                                    :return-value.sync="selectedMonth"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        dark
                                        color="primary"
                                        class="my-2"
                                        depressed
                                      >
                                        <v-icon left>mdi-calendar</v-icon>
                                        {{prettyDate}}
                                        <v-icon right>mdi-chevron-down</v-icon>
                                      </v-btn>
                                    </template>
                                    <v-date-picker
                                      v-model="selectedMonth"
                                      no-title
                                      scrollable
                                      type="month"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="dateMenu = false"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="onChangeMonth(); $refs.dateMenu.save(selectedMonth)"
                                      >
                                        OK
                                      </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </td>
                            <td style="width: 70%;">
                                <div class="status-wrapper">
                                    <div class="status header" v-for="day in monthData.daysInMonth" :key="day">
                                        <span class="dayNumber font-weight-bold">{{day}}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:item.instrument="{ item }">
                        <span class="text-subtitle-2">{{item.name}}</span>
                    </template>
                    <template v-slot:item.data="{ item }">
                        <div class="status-wrapper">
                            <div
                                v-for="(data, dIndex) in item.dataByDay"
                                :key="dIndex"
                                :class="data > 0 ? `status data-purple-${Math.min(data, 5)} text-center` : 'status no-data text-center'"
                            >
                                <span class="dayNumber font-italic"></span>
                            </div>
                        </div>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="dataTableHeaders.length" v-if="item.instruments.length > 0">
                            <v-simple-table class="instrumentsTable">
                                <tr v-for="(instrument, iIndex) in item.instruments" :key="iIndex" class="instrumentRow">
                                    <td style="width: 5%"></td>
                                    <td style="width: 25%">
                                        <v-btn small icon color="primary" @click.stop="openInstrumentDialog(instrument.id)"><v-icon small>mdi-information-outline</v-icon></v-btn>
                                        <span class="text-caption">{{instrument.acronym}}</span>
                                    </td>
                                    <td style="width: 70%">
                                        <div class="status-wrapper">
                                            <template v-for="(level, dIndex) in instrument.dataByDay">
                                                <div
                                                    v-if="level>1"
                                                    :class="`status data-instrument-${level}`"
                                                    :key="dIndex"
                                                    @click.stop="openDataDialog(instrument.id, `${dIndex+1}`)"
                                                ></div>
                                                <div
                                                    v-else-if="level"
                                                    :class="`status data-instrument-${level}`"
                                                    :key="dIndex"
                                                ></div>
                                                <div
                                                    :key="dIndex"
                                                    class="status no-data"
                                                    v-else
                                                ></div>
                                            </template>
                                        </div>
                                    </td>
                                </tr>
                            </v-simple-table>
                        </td>
                    </template>
                </v-data-table>
            </v-row>
        </template>
      </v-flex>
    </v-layout>
      <v-dialog
        v-model="instrumentDialog.display"
        width="600"
      >
        <v-card>
            <v-card-title v-if="instrumentDialog.instrument.acronym" class="text-h5 text-uppercase font-weight-black black--text">
                {{instrumentDialog.instrument.acronym}}
            </v-card-title>
            <v-card-subtitle v-if="instrumentDialog.instrument.name">
                {{instrumentDialog.instrument.name}}
            </v-card-subtitle>
            <v-card-subtitle v-if="instrumentDialog.instrument.pi">
                <v-icon>mdi-account</v-icon> {{instrumentDialog.instrument.pi.first_name}} {{instrumentDialog.instrument.pi.last_name}} ({{instrumentDialog.instrument.pi.email}})
            </v-card-subtitle>
            <v-card-text>
                <v-row class="mx-1 text-center" dense v-if="instrumentDialog.loading">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-row>
                <v-row class="mx-1" dense v-else>
                    <v-col cols="12">
                        <v-card elevation="0" outlined>
                            <v-card-text>
                                <template v-if="instrumentDialog.instrument.info">
                                    <h3 class="text-h6 font-weight-bold" v-if="instrumentDialog.instrument.info.description">Description</h3>
                                    <p v-if="instrumentDialog.instrument.info.description">{{instrumentDialog.instrument.info.description}}</p>
                                </template>
                                
                                <template v-if="instrumentDialog.instrument.products && instrumentDialog.instrument.products.length">
                                    <h3 class="text-h6 font-weight-bold">Variables</h3>
                                    <v-data-table
                                        dense
                                        :headers="instrumentDialog.productsTableHeaders"
                                        :items="instrumentDialogProducts"
                                        :loading="instrumentDialog.loading"
                                        loading-text="Loading... Please wait"
                                        v-if="instrumentDialog.instrument.products"
                                    >
                                        <template v-slot:item.downloadable="{ item }">
                                            <div class="text-center">
                                                <v-icon v-if="item.downloadable">mdi-checkbox-marked</v-icon>
                                                <v-icon v-else>mdi-close-box</v-icon>
                                            </div>
                                        </template>
                                    </v-data-table>
                                </template>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dataDialog.display"
        width="1000"
        scrollable
      >
        <v-card>
            <v-card-title>
                <v-row class="mb-2">
                    <v-col cols="6" class="pb-0">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <h5 class="text-h5 text-uppercase font-weight-black black--text" v-if="dataDialog.selectedInstrument.acronym">{{dataDialog.selectedInstrument.acronym}}</h5>
                                <h5 class="text-caption" v-if="dataDialog.selectedDay">{{dataDialog.selectedDay.toDateString()}}</h5>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                        <v-select
                            v-model="dataDialog.selectedProduct"
                            :items="dataDialogProducts"
                            label="Select Product"
                            dense
                            class="mt-4"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row class="mx-1 text-center" dense v-if="dataDialog.loading">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-row>
                <v-row v-else>
                    <v-col cols="12">
                        <v-data-table
                            dense
                            :headers="dataDialog.tableHeaders"
                            :items="dataDialogFiles"
                            class="dataByDayTable"
                            hide-default-footer
                            :loading="dataDialog.loading"
                            loading-text="Loading... Please wait"
                        >
                            <template v-slot:item.start_date="{ item }">
                                {{parseDate(item.start_date)}}
                            </template>
                            <template v-slot:item.end_date="{ item }">
                                {{parseDate(item.end_date)}}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
      </v-dialog>
  </div>
</template>

<style>
    .data-purple-5 { background-color: #5D3FD3 !important; }
    .data-purple-4 { background-color: #785de3 !important; }
    .data-purple-3 { background-color: #9581e3 !important; }
    .data-purple-2 { background-color: #b3a4ed !important; }
    .data-purple-1 { background-color: #d2c7ff !important; }
    .data-instrument-1 { background-color: #f2dbae !important; }
    .data-instrument-2 { background-color: #caf2c9 !important; }
    .no-data { background-color: transparent !important; }
    span.dayNumber { line-height: 32px; }
    
    tbody tr:hover {
        background-color: transparent !important;
     }
    
    .data-purple-5 span.dayNumber,
    .data-purple-4 span.dayNumber,
    .data-purple-3 span.dayNumber,
    .data-purple-2 span.dayNumber { color: white; }
    
    .data-purple-1 span.dayNumber,
    .data-instrument-1 span.dayNumber,
    .data-instrument-2 span.dayNumber,
    .no-data span.dayNumber { color: black; }
    
    div.v-data-table.dataByDayTable,
    .instrumentsTable {
        width: 100%;
    }
    
    div.status-wrapper {
        display: table;
        table-layout: fixed;
        width: 100%;
        height: 32px;
    }
    
    div.status {
        height: 100%;
        display: table-cell;
        text-align: center;
        vertical-align: center;
        border-left: 1px solid rgb(128, 128, 128);
        height: 32px;
    }
    
    table div.status.data-instrument-2:hover {
        cursor: pointer;
    }
    
    table div.status:first-of-type {
        border-left: none;
        vertical-align: bottom;
    }
    
    div.status.header {
        border: none;
    }
    
    /*
    div.v-data-table.dataByDayTable td div.status,
    .instrumentsTable td div.status {
        height: 32px;
         float: left;
        width: 3.2%;
        border-left: 1px solid rgb(128, 128, 128);
    }
    */
     
    div.v-data-table.dataByDayTable td {
        padding: 0 !important;
        margin: 0;
    }
    
    div.v-data-table.dataByDayTable tr.v-data-table__expanded__content {
        box-shadow: none !important;
    }
    
    .instrumentsTable table {
        width: 100%;
        border-radius: 0 !important;
    }
    
    .instrumentsTable tr.instrumentRow {
        border-bottom: 1px solid rgb(128, 128, 128);
    }
    
    .instrumentsTable tr.instrumentRow:last-of-type {
        border-bottom: none;
    }
</style>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'

import axios from '@/plugins/axios'

export default {
  name: 'Home',
  components: {
  },
  data: () => {
    return {
        dateMenu: false,
        dataLoading: true,
        introTab: 'data-availability',
        instrumentDialog: {
            loading: false,
            display: false,
            instrument: {},
            productsTableHeaders: [
                { text: 'Name', value: 'name', sortable: false },
                { text: 'Downloadable', value: 'downloadable', sortable: false, align: 'center' }
            ]
        },
        dataDialog: {
            loading: false,
            display: false,
            instruments: [],
            selectedInstrument: {},
            selectedProduct: "",
            tableHeaders: [
                { text: 'File', value: 'file_name', sortable: false },
                { text: 'Start', value: 'start_date', sortable: false },
                { text: 'End', value: 'end_date', sortable: false }
            ]
        },
        selectedMonth: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
        dataTableHeaders: [
            { text: '', value: 'data-table-expand', width: '5%' },
            { text: 'Instrument', value: 'instrument', sortable: 'false', width: '25%' },
            { text: 'Data availability', value: 'data', sortable: 'false', width: '70%' },
        ],
        users: [],
        monthData: {daysInMonth: 31, data: [
            {
                name: 'Lidars',
                instruments: [
                    {name: 'Lidar 1', dataByDay: [true,true,false,true,false,false,false,true,false,false,true,true,true,true,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]},
                    {name: 'Lidar 2', dataByDay: [true,true,false,true,false,false,false,true,false,false,true,true,true,true,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]}
                ],
                dataByDay: [2,2,0,2,0,0,0,2,0,0,2,2,2,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
            }, 
            {
                name: 'Ceilometers',
                instruments: [
                    {name: 'Ceilometer 1', dataByDay: [false,true,false,true,false,false,false,true,false,false,true,true,true,true,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]},
                    {name: 'Ceilometer 2', dataByDay: [true,true,false,true,false,false,false,true,false,false,true,true,true,true,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,true]}
                ],
                dataByDay: [1,2,0,2,0,0,0,2,0,0,2,2,2,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1]
            }
        ]}
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'userinfo']),
    ...mapGetters('user', ['fullUserList']),
    currentYear () {
        return parseInt(`${this.selectedMonth.substr(0,4)}`)
    },
    currentMonth () {
        return parseInt(`${this.selectedMonth.substr(5, 2)}`)
    },
    prettyDate () {
        const monthNames = [
            'January', 'February', 'March',
            'April', 'May', 'June',
            'July', 'August', 'September',
            'October', 'November', 'December'
        ];
        
        let monthName = monthNames[ parseInt(this.selectedMonth.substr(5, 2))-1 ]
        
        return `${monthName} ${this.selectedMonth.substr(0,4)}`
    },
    dataDialogInstruments () {
        return this.dataDialog.instruments.map(instrument => {
            return {
                text: instrument.acronym,
                value: instrument.id
            }
        });
    },
    dataDialogProducts () {
        return this.dataDialog.instruments.filter(instrument => {
            return instrument.id==this.dataDialog.selectedInstrument.id;
        }).reduce((a, instrument) => {
            return a.concat( instrument.products.filter(p=>p.level>1).map(product => {
                return {
                    text: product.acronym,
                    value: product.id
                }
            }));
        }, [])
    },
    dataDialogFiles () {
        let data = []
        
        this.dataDialog.instruments.forEach ( instrument => {
            if ( instrument.id == this.dataDialog.selectedInstrument.id ) {
                instrument.products.forEach ( product => {
                    if ( product.id == this.dataDialog.selectedProduct ) {
                        data = product.data;
                    }
                })
            }
        })
        
        return data;
    },
    instrumentDialogProducts () {
        if ( !this.instrumentDialog.instrument.products ) {
            return []
        }
        
        return this.instrumentDialog.instrument.products.filter(p => p.level>1)
    }
  },
  methods: {
    ...mapActions ('user', ['getFullUserList']),
    parseDate ( stringDate ) {
        return new Date (stringDate).toLocaleString('en-GB', {timeZone: 'UTC'});
    },
    async onChangeMonth () {
        let requestUrl = `/data/month-overview/${this.selectedMonth.substr(0,4)}/${this.selectedMonth.substr(5, 2)}`;
        
        this.dataLoading = true;
        this.monthData.data = [];
        
        let monthDataResponse = await axios({
            url: requestUrl,
            method: 'GET'
        })
        
        this.monthData = monthDataResponse.data.data
        
        this.dataLoading = false;
    },
    async openInstrumentDialog ( instrumentId ) {
        this.instrumentDialog.loading = true;
        this.instrumentDialog.display = true;
        
        let instrumentResponse = await axios({
            url: `/instruments/${instrumentId}/description`,
            method: 'GET'
        });
        
        this.instrumentDialog.instrument = instrumentResponse.data.data;
        
        this.instrumentDialog.loading = false;
    },
    async openDataDialog ( instrumentId, monthDay ) {
        this.dataDialog.loading = true;
        this.dataDialog.display = true;
        this.dataDialog.selectedProduct = "";
        
        let instrumentResponse = await axios({
            url: `/data/available/${instrumentId}/${this.currentYear}${this.currentMonth}${monthDay}/${this.currentYear}${this.currentMonth}${monthDay}`,
            method: 'GET'
        });
        
        this.dataDialog.instruments = instrumentResponse.data.data;
        // this.dataDialog.selectedInstrument.id = instrumentId;
        this.dataDialog.selectedInstrument = this.dataDialog.instruments[0]
        this.dataDialog.selectedDay = new Date (`${this.currentYear}-${this.currentMonth}-${monthDay}`)
        
        this.dataDialog.loading = false;
        
        console.log ( this.dataDialogProducts )
    }
  },
  async mounted() {
    // await this.getFullUserList();
    await this.onChangeMonth();
  }
}
</script>
