import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            auth: false
        }
    }, {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            auth: false
        }
    }, {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: {
            auth: true
        }
    }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
